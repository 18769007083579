<sub-menu items="edit.submenu.items" fluid="true" form="form"></sub-menu>
<main-menu items="edit.mainmenu.items"></main-menu>

<ng-form name="form" class="base-layout parcel-edit">
    <div class="base-layout-map">
        <div class="base-layout-map-content parcel-edit-params">
            <uib-tabset active="active">
                <uib-tab heading="{{'PARCELS_PARAM_TAB_1' | translate}}" ng-if="!edit.createNewParcel">
                    <main-message message="edit.message" fluid="'nested'"></main-message>
                    <div class="form-group"
                         ng-class="{
                 'has-error': form.label.$invalid
               }">
                        <label class="control-label">{{'PARCELS_PARAM_LABEL' | translate}}</label>
                        <input class="form-control"
                               type="text"
                               name="label"
                               ng-maxlength="100"
                               ng-readonly="edit.isShared"
                               ng-model="edit.item.label"
                               ng-disabled="true"
                               required/>
                        <p class="help-block"
                           ng-messages="form.label.$error">
                            <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 100})}}</span>
                        </p>
                    </div>
                    <div class="form-group"
                         ng-class="{
                 'has-error': form.customIdentifier.$invalid
               }">
                        <label class="control-label">{{'site.customIdentifier.label' | translate}}</label>
                        <input class="form-control"
                               type="text"
                               name="customIdentifier"
                               ng-readonly="edit.isShared"
                               ng-maxlength="255"
                               ng-disabled="!edit.fullEditAvailable"
                               ng-model="edit.item.customIdentifier"/>
                        <p class="help-block"
                           ng-messages="form.customIdentifier.$error">
                            <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
                        </p>
                    </div>
                    <hr/>
                    <ng-include src="'parcels/edit/views/region.html'"></ng-include>
                    <ng-include src="'parcels/edit/views/locality.html'"></ng-include>
                    <div ng-if="edit.hasAssetType('VINEYARD')">
                        <ng-include src="'parcels/edit/views/district.html'"></ng-include>
                        <ng-include src="'parcels/edit/views/winelocation.html'"></ng-include>
                        <ng-include src="'parcels/edit/views/flurnumber.html'"></ng-include>
                    </div>
                    <div class="form-group"
                         ng-class="{
                 'has-error': form.business.$invalid
               }">
                        <label class="control-label" ng-if="edit.businessLabel === null && !edit.domainWachau">{{ 'PARCELS_PARAM_WINERY' | translate }}</label>
                        <label class="control-label" ng-if="edit.businessLabel !== null">{{ edit.businessLabel }}</label>
                        <label class="control-label" ng-if="edit.businessLabel === null && edit.domainWachau">Winzer</label>
                        <select class="form-control" ng-if="!edit.isShared"
                                ng-options="business.id as business.label for business in edit.businesses"
                                ng-model="edit.item.businessId"
                                name="business"
                                ng-disabled="!edit.fullEditAvailable"
                                required>
                            <option value=""
                                    ng-if="!edit.item.businessId">{{'MAKE_CHOICE' | translate}}
                            </option>
                        </select>
                        <input class="form-control" ng-if="edit.isShared"
                               type="text"
                               ng-value="edit.item.businessLabel"
                               name="sharedBusiness"
                               ng-readonly="edit.isShared"/>
                    </div>
                    <div class = "form-group" ng-if="edit.item.customerUuid !== edit.ownCustomerUuid">
                            <label class="control-label">{{('PARCELS_PARAM_WINERY_PHONE' | translate)}}</label>
                            <input
                            class="form-control"
                            ng-readonly="true"
                            ng-model="edit.item.businessPhoneNumber"
                            />
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'PARCELS_PARAM_MANAGEMENT_UNIT' | translate}}</label>
                        <ui-select ng-model="edit.item.managementUnitId" theme="bootstrap" style="margin-bottom: 5px;" ng-disabled="true">
                            <ui-select-match placeholder="{{'PLEASE_MAKE_CHOICE' | translate}}">{{$select.selected.label}}
                                <a aria-label="Select box clear" ng-click="edit.removeSelectedManagementUnit()"
                                   class="btn btn-xs btn-link pull-right" aria-hidden="false">
                                    <i class="glyphicon glyphicon-remove" aria-hidden="true"></i>
                                </a>
                            </ui-select-match>
                            <ui-select-choices repeat="mu in edit.getAvailableManagementUnits($select.search)">
                                {{mu.label}}
                            </ui-select-choices>
                        </ui-select>
                    </div>
                    <!-- SET CONTRACTOR -->
                    <div class="form-group"
                         ng-class="{'has-error': formBase.contractor.$invalid}">
                        <label class="control-label"> {{'customer.isContractor.label' | translate}}</label>
                        <ui-select theme="bootstrap"
                                   id="contractor"
                                   name="contractor"
                                   ng-disabled="true"
                                   ng-model="edit.item.contractor">
                            <ui-select-match allow-clear="true" placeholder="{{'PLEASE_MAKE_CHOICE' | translate}}">{{$select.selected.label}}
                            </ui-select-match>
                            <ui-select-choices repeat="contractor.uuid as contractor in edit.contractors" ui-disable-choice="contractor.uuid === edit.ownCustomerUuid">
                                <div ng-bind-html="contractor.label"></div>
                            </ui-select-choices>
                        </ui-select>
                    </div>

                    <div class="form-group" ng-if="edit.hasTimeTrackingModule">
                        <label class="control-label">{{'TIME_TRACKING_COST_CENTER' | translate}}</label>
                        <ui-select ng-model="edit.item.costCenterId" theme="bootstrap" style="margin-bottom: 5px;" ng-disabled="true">
                            <ui-select-match allow-clear="true" placeholder="  {{'PLEASE_MAKE_CHOICE' | translate}}">
                                {{$select.selected.indentedLabel}}
                            </ui-select-match>
                            <ui-select-choices group-by="edit.groupCostCenters"
                                               repeat="cc.id as cc in edit.getAvailableCostCenters($select.search)">
                                {{cc.indentedLabel}}
                            </ui-select-choices>
                        </ui-select>
                    </div>
                    <ng-include src="'parcels/edit/views/leasing.html'"></ng-include>
                    <hr/>
                    <div class="form-group">
                        <label class="control-label">{{'PARCELS_PARAM_AREA' | translate}}</label>
                        <div class="form-control-static">
              <span
                      ng-if="edit.item.area">{{edit.item.area | number:4}} {{'PARCELS_PARAM_AREA_UNIT' | translate}}</span>
                            <span ng-if="!edit.item.area">-</span>
                        </div>
                    </div>
                    <div ng-if="edit.hasAssetType('VINEYARD')">
                        <ng-include src="'parcels/edit/views/calculated_fields.html'"></ng-include>
                        <ng-include src="'parcels/edit/views/fertilization_info.html'"></ng-include>
                    </div>
                </uib-tab>

                <uib-tab select="edit.renderSlider()" ng-if="!edit.createNewParcel" heading="{{'PARCELS_PARAM_TAB_2' | translate}}"
                         ng-if="!edit.createNewParcel && edit.hasAssetType('VINEYARD')">
                    <ng-include src="'parcels/edit/views/planting.html'"></ng-include>
                </uib-tab>
                <uib-tab heading="Bewirtschaftung" select="edit.renderSlider()" ng-if="!edit.createNewParcel && edit.domainWachau">
                    <ng-include ng-if="edit.hasAssetType('VINEYARD')"
                                src="'parcels/edit/views/stick_distance.html'"></ng-include>
                    <hr ng-if="edit.hasAssetType('VINEYARD')"/>
                    <div ng-if="edit.hasAssetType('VINEYARD')" class="form-group">
                        <label class="control-label">Reihenabstand (cm)</label>
                        <input type="hidden" ng-model="edit.item.laneWidth"/>
                        <input class="form-control"
                               ng-readonly="!edit.fullEditAvailable || edit.isShared"
                               type="number"
                               ng-model="edit.visibleLaneWidth"
                               ng-change="edit.updateHiddenField()"
                        />
                    </div>
                    <hr/>
                    <div class="form-group">
                        <label class="control-label">{{'PARCELS_PARAM_NOTE1' | translate}}</label>
                        <input class="form-control"
                               type="text"
                               ng-maxlength="255"
                               ng-readonly="edit.isShared"
                               ng-model="edit.item.note1"/>
                        <p class="help-block"
                           ng-messages="form.note1.$error">
                            <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
                        </p>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'PARCELS_PARAM_NOTE2' | translate}}</label>
                        <input class="form-control"
                               type="text"
                               ng-maxlength="255"
                               ng-readonly="edit.isShared"
                               ng-model="edit.item.note2"/>
                        <p class="help-block"
                           ng-messages="form.note2.$error">
                            <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
                        </p>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'site.note3.label' | translate}}</label>
                        <input class="form-control"
                               type="text"
                               ng-maxlength="255"
                               ng-readonly="edit.isShared"
                               ng-model="edit.item.note3"/>
                        <p class="help-block"
                           ng-messages="form.note3.$error">
                            <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
                        </p>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'site.note4.label' | translate}}</label>
                        <input class="form-control"
                               type="text"
                               ng-maxlength="255"
                               ng-readonly="edit.isShared"
                               ng-model="edit.item.note4"/>
                        <p class="help-block"
                           ng-messages="form.note4.$error">
                            <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
                        </p>
                    </div>
                    <div class="checkbox" style="margin-bottom: 15px">
                        <label style="font-weight: 700">
                            <input type="checkbox"
                                   ng-disabled="!edit.fullEditAvailable || edit.isShared"
                                   ng-model="edit.item.steep" />
                            {{'site.steep.label' | translate}}
                        </label>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'site.slope.label' | translate}}</label>
                        <input class="form-control"
                               type="number"
                               ng-readonly="edit.isShared"
                               ng-model="edit.item.slope"/>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'site.slopeFacing.label' | translate}}</label>
                        <input class="form-control"
                               type="text"
                               ng-maxlength="255"
                               ng-readonly="edit.isShared"
                               ng-model="edit.item.slopeFacing"/>
                        <p class="help-block"
                           ng-messages="form.slopeFacing.$error">
                            <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
                        </p>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'site.dangerCategory.label' | translate}}</label>
                        <input class="form-control"
                               type="text"
                               ng-maxlength="255"
                               ng-readonly="edit.isShared"
                               ng-model="edit.item.dangerCategory"/>
                        <p class="help-block"
                           ng-messages="form.dangerCategory.$error">
                            <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
                        </p>
                    </div>
                    <div ng-if="edit.hasAssetType('VINEYARD')">
                        <hr />
                        <div class="form-group">
                            <label class="control-label">{{'PARCELS_PARAM_YIELD' | translate}}</label>
                            <input class="form-control"
                                   type="number"
                                   ng-readonly="edit.isShared"
                                   ng-model="edit.item.yield"/>
                        </div>
                        <hr />
                        <div class="form-group">
                            <label class="control-label">{{'PARCELS_PARAM_FLIK' | translate}}</label>
                            <input class="form-control"
                                   type="text"
                                   ng-maxlength="255"
                                   ng-readonly="!edit.fullEditAvailable || edit.isShared"
                                   ng-model="edit.item.flik"/>
                            <p class="help-block"
                               ng-messages="form.flik.$error">
                                <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
                            </p>
                        </div>
                    </div>
                </uib-tab>
                <uib-tab heading="{{'PARCELS_PARAM_TAB_3' | translate}}" select="edit.renderSlider()" ng-if="!edit.createNewParcel && !edit.domainWachau">
                    <ng-include ng-if="edit.hasAssetType('VINEYARD')"
                                src="'parcels/edit/views/stick_distance.html'"></ng-include>
                    <hr ng-if="edit.hasAssetType('VINEYARD')"/>
                    <div ng-if="edit.hasAssetType('VINEYARD')" class="form-group">
                        <label class="control-label">{{'PARCELS_PARAM_LANE_WIDTH' | translate}}</label>
                        <input class="form-control"
                               ng-readonly="!edit.fullEditAvailable || edit.isShared"
                               type="number"
                               ng-model="edit.item.laneWidth"/>
                    </div>
                    <hr/>
                    <div class="form-group">
                        <label class="control-label">{{'PARCELS_PARAM_NOTE1' | translate}}</label>
                        <input class="form-control"
                               type="text"
                               ng-maxlength="255"
                               ng-readonly="edit.isShared"
                               ng-disabled="true"
                               ng-model="edit.item.note1"/>
                        <p class="help-block"
                           ng-messages="form.note1.$error">
                            <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
                        </p>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'PARCELS_PARAM_NOTE2' | translate}}</label>
                        <input class="form-control"
                               type="text"
                               ng-maxlength="255"
                               ng-readonly="edit.isShared"
                               ng-disabled="true"
                               ng-model="edit.item.note2"/>
                        <p class="help-block"
                           ng-messages="form.note2.$error">
                            <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
                        </p>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'site.note3.label' | translate}}</label>
                        <input class="form-control"
                               type="text"
                               ng-maxlength="255"
                               ng-readonly="edit.isShared"
                               ng-disabled="true"
                               ng-model="edit.item.note3"/>
                        <p class="help-block"
                           ng-messages="form.note3.$error">
                            <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
                        </p>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'site.note4.label' | translate}}</label>
                        <input class="form-control"
                               type="text"
                               ng-maxlength="255"
                               ng-readonly="edit.isShared"
                               ng-disabled="true"
                               ng-model="edit.item.note4"/>
                        <p class="help-block"
                           ng-messages="form.note4.$error">
                            <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
                        </p>
                    </div>
                    <div class="checkbox" style="margin-bottom: 15px">
                        <label style="font-weight: 700">
                            <input type="checkbox"
                                   ng-disabled="!edit.fullEditAvailable || edit.isShared"
                                   ng-model="edit.item.steep" />
                            {{'site.steep.label' | translate}}
                        </label>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'site.slope.label' | translate}}</label>
                        <input class="form-control"
                               type="number"
                               ng-readonly="edit.isShared"
                               ng-disabled="true"
                               ng-model="edit.item.slope"/>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'site.slopeFacing.label' | translate}}</label>
                        <input class="form-control"
                               type="text"
                               ng-maxlength="255"
                               ng-disabled="true"
                               ng-readonly="edit.isShared"
                               ng-model="edit.item.slopeFacing"/>
                        <p class="help-block"
                           ng-messages="form.slopeFacing.$error">
                            <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
                        </p>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'site.dangerCategory.label' | translate}}</label>
                        <input class="form-control"
                               type="text"
                               ng-maxlength="255"
                               ng-disabled="true"
                               ng-readonly="edit.isShared"
                               ng-model="edit.item.dangerCategory"/>
                        <p class="help-block"
                           ng-messages="form.dangerCategory.$error">
                            <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
                        </p>
                    </div>
                    <div ng-if="edit.hasAssetType('VINEYARD')">
                        <hr />
                        <div class="form-group">
                            <label class="control-label">{{'PARCELS_PARAM_YIELD' | translate}}</label>
                            <input class="form-control"
                                   type="number"
                                   ng-disabled="true"
                                   ng-readonly="edit.isShared"
                                   ng-model="edit.item.yield"/>
                        </div>
                        <hr />
                        <div class="form-group">
                            <label class="control-label">{{'PARCELS_PARAM_FLIK' | translate}}</label>
                            <input class="form-control"
                                   type="text"
                                   ng-maxlength="255"
                                   ng-readonly="!edit.fullEditAvailable || edit.isShared"
                                   ng-model="edit.item.flik"/>
                            <p class="help-block"
                               ng-messages="form.flik.$error">
                                <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
                            </p>
                        </div>
                    </div>
                </uib-tab>
                <uib-tab heading="{{'PARCELS_PARAM_TAB_4' | translate}}" ng-if="!edit.createNewParcel">
                    <!-- <button ui-sref="parcels.edit.orders.list">Regular orders</button>
                    <button ui-sref="parcels.edit.orders.cyclic">Cyclic orders</button> -->

                    <uib-tabset justified="true" type="pills" active="edit.active">
                        <uib-tab
                                index="0"
                                heading="{{'PARCELS_PARAM_TAB_4_1' | translate}}"
                                ui-sref="parcels.edit.orders.list">
                            <ui-view></ui-view>
                        </uib-tab>
                        <uib-tab
                                index="1"
                                heading="{{'PARCELS_PARAM_TAB_4_2' | translate}}"
                                ui-sref="parcels.edit.orders.cyclic">
                            <ui-view></ui-view>
                        </uib-tab>
                    </uib-tabset>
                    <!-- <ui-view></ui-view> -->
                </uib-tab>
                <uib-tab heading="{{'PARCELS_PARAM_TAB_5' | translate}}"
                         ng-if="!edit.createNewParcel && edit.fertilization && ('PARCELS_PARAM_TAB_5' | translate) !== 'PARCELS_PARAM_TAB_5'">
                    <ng-include src="'parcels/edit/views/edit-fertilization.tpl.html'"></ng-include>
                </uib-tab>
                <uib-tab heading="{{(edit.boniturLabel ? edit.boniturLabel : 'Bonitur') | translate}}" ng-if="!edit.createNewParcel && edit.hasBoniturModule">
                    <ng-include src="'parcels/edit/views/bonitur.tpl.html'"></ng-include>
                </uib-tab>
                <uib-tab heading="{{'Restriktionen' | translate}}" ng-if="!edit.createNewParcel && edit.restrictions.length > 0">
                    <ng-include src="'parcels/edit/views/restrictions.tpl.html'"></ng-include>
                </uib-tab>
                <uib-tab heading="{{'SERVICE.HARVEST_PLANNING' | translate}}"
                         ng-if="!edit.createNewParcel && edit.hasHarvestPlanningModule">
                    <ng-include src="'parcels/edit/views/harvest_planning.tpl.html'"></ng-include>
                </uib-tab>
                <uib-tab heading="{{'PARCELS_PARAM_TAB_7' | translate}}"
                         ng-if="edit.hasShapeConverterModule && edit.createNewParcel">
                    <ng-include src="'parcels/edit/views/shapeConverter.tpl.html'"></ng-include>
                </uib-tab>
                <uib-tab heading="{{'PARCELS_PARAM_TAB_8' | translate}}"
                         ng-if="edit.hasShapeConverterModule && edit.createNewParcel">
                    <ng-include src="'parcels/edit/views/shapeUploader.tpl.html'"></ng-include>
                </uib-tab>
            </uib-tabset>
        </div>
        <div class="base-layout-map-map">
            <div class="map-container">
                <vc-map-display
                        ng-if="!edit.mapEditModeEnabled"
                        map-id="edit.mapId"
                        vineyard-map-data="edit.mapData"
                        selected-vineyards="edit.selectedVineyards"
                        icon-data="edit.iconData"
                        zoom-to-selection="'both'">
              <span
                      class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block glyphicon glyphicon-pencil"
                      aria-hidden="true"></span> <span
                                class="hidden-xs hidden-sm hidden-md">{{'WINERY_MANAGEMENT_PARCELS_MAP_ENABLE_EDIT_MODE' | translate}}</span>
                        </button>
                    </custom-control>
                </vc-map-display>
                <vc-map-edit
                        ng-if="edit.mapEditModeEnabled"
                        map-id="edit.mapIdEdit"
                        polygons="edit.polygons"
                        polylines="edit.polylines"
                        markers="edit.beacons"
                        trigger-zoom="edit.triggerZoom"
                        drawing-manager-options="{
            drawingControlOptions: {
              drawingModes: edit.canDrawPolylines() ? ['polyline'] : ['polygon'],
              position: 7
            },
            polygonOptions: edit.polygonOptions,
            polylineOptions: edit.polygonOptions,
            drawingControl: edit.mapEditMode === 'manual' || edit.canDrawPolylines()
          }"
                        drawing-manager-events="edit.drawingManagerEvents"
                        object-events="edit.objectEvents">
                    <custom-control class="additional-button" position="TOP_RIGHT" index="1">
                        <div class="btn-group"
                             ng-if="edit.mapEditMode === null && !edit.createNewParcel">
                            <label class="btn btn-default"
                                   ng-model="edit.mapEditMode"
                                   uib-btn-radio="'manual'">
                <span
                        class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block glyphicon glyphicon-pencil"
                        aria-hidden="true"></span> <span
                                    class="hidden-xs hidden-sm hidden-md">{{'WINERY_MANAGEMENT_PARCELS_MAP_MODE_MANUAL' | translate}}</span>
                            </label>
                            <label for="kml-file-upload"
                                   class="btn btn-default">
                <span
                        class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block glyphicon glyphicon-cloud-upload"
                        aria-hidden="true"></span> <span
                                    class="hidden-xs hidden-sm hidden-md">{{'WINERY_MANAGEMENT_PARCELS_MAP_MODE_BY_KML_FILE' | translate}}</span>
                            </label>
                        </div>
                        <input type="file"
                               id="kml-file-upload"
                               ng-if="edit.mapEditMode === null"
                               nv-file-select
                               class="hidden"
                               uploader="edit.uploader"/>
                        <button class="btn btn-default"
                                ng-click="edit.resetMap()"
                                ng-if="edit.mapEditMode !== null">
              <span
                      class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block glyphicon glyphicon-remove"
                      aria-hidden="true"></span> <span
                                class="hidden-xs hidden-sm hidden-md">{{'WINERY_MANAGEMENT_PARCELS_MAP_RESET' | translate}}</span>
                        </button>
                        <button class="btn btn-default"
                                ng-click="edit.deleteRow(edit.getSelectedPolyline())"
                                ng-if="edit.getSelectedPolyline() !== null">
              <span
                      class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block glyphicon glyphicon-trash"
                      aria-hidden="true"></span> <span
                                class="hidden-xs hidden-sm hidden-md">{{'WINERY_MANAGEMENT_PARCELS_MAP_DELETE_ROW' | translate}}</span>
                        </button>
                        <button class="btn btn-default"
                                ng-click="edit.deleteBeacon(edit.getSelectedPoint())"
                                ng-if="edit.getSelectedPoint() !== null">
              <span
                      class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block glyphicon glyphicon-trash"
                      aria-hidden="true"></span> <span
                                class="hidden-xs hidden-sm hidden-md">{{'WINERY_MANAGEMENT_PARCELS_MAP_DELETE_BEACON' | translate}}</span>
                        </button>
                        <button class="btn btn-default"
                                ng-click="edit.addBeacon(edit.getSelectedPolyline())"
                                ng-if="edit.getSelectedPolyline() !== null && edit.beacons.length < edit.maxBeaconsPerVineyard">
              <span
                      class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block glyphicon glyphicon-map-marker"
                      aria-hidden="true"></span> <span
                                class="hidden-xs hidden-sm hidden-md">{{'WINERY_MANAGEMENT_PARCELS_MAP_ADD_BEACON' | translate}}</span>
                        </button>
                        <button class="btn btn-default"
                                ng-click="edit.editBeacon(edit.getSelectedPoint())"
                                ng-if="edit.getSelectedPoint() !== null">
              <span
                      class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block glyphicon glyphicon-pencil"
                      aria-hidden="true"></span> <span
                                class="hidden-xs hidden-sm hidden-md">{{'WINERY_MANAGEMENT_PARCELS_MAP_EDIT_BEACON' | translate}}</span>
                        </button>
                    </custom-control>
                </vc-map-edit>
            </div>
        </div>
    </div>
</ng-form>

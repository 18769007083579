<!-- LEVEL1 -->
<div class="form-group">
  <label class="control-label" ng-if="!edit.domainWachau">{{'PARCELS_PARAM_VINE' | translate}}</label>
  <label class="control-label" ng-if="edit.domainWachau">Rebsorte</label>
  <select class="form-control"
        ng-options="(fWine | translate)  as (fWine | translate) for fWine in edit.allWines.firstWines"
        ng-disabled="!edit.fullEditAvailable || edit.isShared"
        ng-model="edit.item.wineFirst"
        ng-change="edit.item.wineSecond = null; edit.item.wineThird = null; edit.translateValue(edit.item.wineFirst, 'wineFirst');">
    <option value="">{{'MAKE_CHOICE' | translate}}</option>
    {{'o' | translate}}
  </select>
</div>
<!-- LEVEL2 -->
<div class="form-group">
  <select class="form-control"
        ng-options="(sWine | translate) as (sWine | translate) for sWine in edit.allWines.tree[edit.keys.wineFirst]"
        ng-disabled="!edit.fullEditAvailable || edit.isShared"
        ng-model="edit.item.wineSecond"
        ng-if="edit.item.wineFirst"
        ng-change="edit.item.wineThird = null; edit.translateValue(edit.item.wineSecond, 'wineSecond');">
    <option value="">{{'MAKE_CHOICE' | translate}}</option>
    {{'o' | translate}}
  </select>
</div>
<!-- LEVEL3 -->
<div class="form-group">
  <select class="form-control"
        ng-options="(tWine | translate) as (tWine | translate) for tWine in edit.allWines.tree[edit.keys.wineSecond]"
        ng-disabled="!edit.fullEditAvailable || edit.isShared"
        ng-model="edit.item.wineThird"
        ng-if="edit.item.wineSecond">
    <option value="">{{'MAKE_CHOICE' | translate}}</option>
    {{'o' | translate}}
  </select>
</div>
<hr>
<div class="checkbox" style="margin-bottom: 15px">
    <label style="font-weight: 700">
        <input type="checkbox"
               ng-disabled="!edit.fullEditAvailable || edit.isShared"
               ng-model="edit.item.organicEligible" />
        {{'site.organicEligible.label' | translate}}
    </label>
</div>
<div class="form-group">
    <label class="control-label">{{'PARCELS_PARAM_ORGANIC' | translate}}</label>
    <select class="form-control"
            ng-disabled="!edit.fullEditAvailable || edit.isShared"
            ng-model="edit.item.organic">
        <option value="">{{'PLEASE_MAKE_CHOICE' | translate}}</option>
        <option value="YES">{{'PARCELS_PARAM_ORGANIC_YES' | translate}}</option>
        <option value="TRANSITION">{{'PARCELS_PARAM_ORGANIC_TRANSITION' | translate}}</option>
        <option value="NO">{{'PARCELS_PARAM_ORGANIC_NO' | translate}}</option>
    </select>
</div>
<div class="form-group">
    <label class="control-label">{{'site.vineTraining.label' | translate}}</label>
    <input class="form-control"
           type="text"
           ng-disabled="true"
           ng-maxlength="255"
           ng-readonly="edit.isShared"
           ng-model="edit.item.vineTraining" />
    <p class="help-block"
       ng-messages="form.vineTraining.$error">
        <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
    </p>
</div>
<div class="checkbox" style="margin-bottom: 15px">
    <label style="font-weight: 700">
        <input type="checkbox"
               ng-disabled="!edit.fullEditAvailable || edit.isShared"
               ng-model="edit.item.testingGround" />
        {{'site.testingGround.label' | translate}}
    </label>
</div>
<div class="form-group" ng-if="edit.cultivationProgram.length == 0">
  <label class="control-label" ng-if="edit.cultivationProgramLabel === null">{{ 'PARCELS_PARAM_CULTIVATION_PROGRAM' | translate }}</label>
  <label class="control-label" ng-if="edit.cultivationProgramLabel !== null">{{ edit.cultivationProgramLabel }}</label>
  <input class="form-control"
         ng-readonly="!edit.fullEditAvailable || edit.isShared"
         type="text"
         name="cultivationProgram"
         id="cultivationProgram"
         ng-maxlength="100"
         ng-model="edit.item.cultivationProgram" />
    <p class="help-block"
       ng-messages="form.cultivationProgram.$error">
        <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 100})}}</span>
    </p>
</div>
<div class="form-group" ng-if="edit.cultivationProgram.length > 0">
    <label class="control-label" ng-if="edit.cultivationProgramLabel === null">{{ 'PARCELS_PARAM_CULTIVATION_PROGRAM' | translate }}</label>
    <label class="control-label" ng-if="edit.cultivationProgramLabel !== null">{{ edit.cultivationProgramLabel }}</label>
  <select class="form-control"
          name="cultivationProgram"
          id="cultivationProgram"
          ng-disabled="!edit.fullEditAvailable || edit.isShared"
          ng-model="edit.item.cultivationProgram"
          ng-options="item.value as item.label for item in edit.cultivationProgram">
    <option value="">{{'MAKE_CHOICE' | translate}}</option>
  </select>
</div>
<div class="form-group" ng-if="edit.clone.length == 0">
  <label class="control-label" for="clone">{{'PARCELS_PARAM_CLONE' | translate}}</label>
  <input class="form-control"
          type="text"
          name="clone"
          id="clone"
          ng-maxlength="100"
          ng-disabled="!edit.fullEditAvailable || edit.isShared"
          ng-model="edit.item.clone" />
    <p class="help-block"
        ng-messages="form.clone.$error">
        <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 100})}}</span>
    </p>
</div>
<div class="form-group" ng-if="edit.clone.length > 0">
  <label class="control-label" for="clone">{{'PARCELS_PARAM_CLONE' | translate}}</label>
  <select class="form-control"
          name="clone"
          id="clone"
          ng-disabled="!edit.fullEditAvailable || edit.isShared"
          ng-model="edit.item.clone"
          ng-options="item.value as item.label for item in edit.clone">
    <option value="">{{'MAKE_CHOICE' | translate}}</option>
  </select>
</div>
<div class="form-group">
  <label class="control-label">{{'PARCELS_PARAM_UNDERLAY' | translate}}</label>
  <select class="form-control"
          ng-disabled="edit.isShared"
          ng-disabled="true"
          ng-model="edit.item.underlay">
    <option value="">{{'PLEASE_MAKE_CHOICE' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_01' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_02' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_03' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_04' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_05' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_06' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_07' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_08' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_09' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_10' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_11' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_12' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_13' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_14' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_15' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_16' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_17' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_18' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_19' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_20' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_21' | translate}}</option>
    <option>{{'PARCELS_PARAM_UNDERLAY_22' | translate}}</option>
  </select>
</div>
<br />
<div class="form-group">
  <label class="control-label">{{'PARCELS_PARAM_BBCH' | translate}}</label>
  <div>
    <rzslider rz-slider-model="edit.item.bbch"
              rz-slider-options="edit.sliderOptions.bbch">
    </rzslider>
  </div>
</div>
<br />
<div class="form-group">
  <label class="control-label">{{'PARCELS_PARAM_PLANTEDDATE' | translate}}</label>
  <div class="input-group">
    <input class="form-control"
           uib-datepicker-popup="MMMM yyyy"
           ng-model="edit.item.plantedDate"
           ng-change="edit.validateYieldYear()"
           readonly="readonly"
           is-open="edit.item.plantedDateOpen"
           type="text"
           show-button-bar="false"
           datepicker-options="{
             datepickerMode: 'month',
             minMode: 'month'
           }" />
    <div class="input-group-btn">
      <button class="btn btn-default" ng-if="!edit.isShared"
              ng-click="edit.item.plantedDateOpen = !edit.item.plantedDateOpen">
        <span class="glyphicon glyphicon-calendar"></span>
      </button>
        <button class="btn btn-default" disabled ng-if="edit.isShared">
            <span class="glyphicon glyphicon-calendar"></span>
        </button>
    </div>
  </div>
</div>
<div class="form-group">
  <label class="control-label">{{'PARCELS_PARAM_YIELD_YEAR' | translate}}</label>
  <div class="input-group">
    <input class="form-control"
           uib-datepicker-popup="yyyy"
           ng-model="edit.item.yieldYear"
           readonly="readonly"
           is-open="edit.item.yieldYearOpen"
           type="text"
           show-button-bar="false"
           datepicker-options="{
             datepickerMode: 'year',
             minMode: 'year'
           }" />
    <div class="input-group-btn">
      <button class="btn btn-default" ng-if="!edit.isShared"
              ng-click="edit.item.yieldYearOpen = !edit.item.yieldYearOpen">
        <span class="glyphicon glyphicon-calendar"></span>
      </button>
        <button class="btn btn-default" disabled ng-if="edit.isShared">
            <span class="glyphicon glyphicon-calendar"></span>
        </button>
    </div>
  </div>
</div>
<div class="form-group">
  <label class="control-label">{{'PARCELS_PARAM_CLEAREDDATE' | translate}}</label>
  <div class="input-group">
    <input class="form-control"
           uib-datepicker-popup="MMMM yyyy"
           ng-model="edit.item.clearedDate"
           readonly="readonly"
           is-open="edit.item.clearedDateOpen"
           type="text"
           show-button-bar="false"
           datepicker-options="{
             datepickerMode: 'month',
             minMode: 'month'
           }" />
    <div class="input-group-btn">
      <button class="btn btn-default" ng-if="!edit.isShared"
              ng-click="edit.item.clearedDateOpen = !edit.item.clearedDateOpen">
        <span class="glyphicon glyphicon-calendar"></span>
      </button>
        <button class="btn btn-default" disabled ng-if="edit.isShared">
            <span class="glyphicon glyphicon-calendar"></span>
        </button>
    </div>
  </div>
</div>
<hr />
<div class="form-group">
  <label class="control-label">{{'PARCELS_PARAM_INFECTED_WITH' | translate}}</label>
  <select class="form-control"
          ng-disabled="true"
          ng-model="edit.item.infestedWith">
        <option value="">{{'PARCELS_PARAM_INFECTED_WITH_ONONE' | translate}}</option>
        <option>{{'PARCELS_PARAM_INFECTED_WITH_O1' | translate}}</option>
        <option>{{'PARCELS_PARAM_INFECTED_WITH_O2' | translate}}</option>
        <option>{{'PARCELS_PARAM_INFECTED_WITH_O3' | translate}}</option>
        <option>{{'PARCELS_PARAM_INFECTED_WITH_O4' | translate}}</option>
        <option>{{'PARCELS_PARAM_INFECTED_WITH_O5' | translate}}</option>
        <option>{{'PARCELS_PARAM_INFECTED_WITH_O6' | translate}}</option>
        <option>{{'PARCELS_PARAM_INFECTED_WITH_O7' | translate}}</option>
  </select>
</div>
<div class="form-group">
  <select class="form-control"
          ng-disabled="edit.isShared"
          ng-if="edit.item.infestedWith"
          ng-model="edit.item.infestedSeverity">
    <option value="">{{'PLEASE_MAKE_CHOICE' | translate}}</option>
    <option>{{'PARCELS_PARAM_INFECTED_SEVERITY_O1' | translate}}</option>
    <option>{{'PARCELS_PARAM_INFECTED_SEVERITY_O2' | translate}}</option>
    <option>{{'PARCELS_PARAM_INFECTED_SEVERITY_O3' | translate}}</option>
  </select>
</div>
<div class="checkbox" style="margin-bottom: 15px">
    <label style="font-weight: 700">
        <input type="checkbox"
               ng-disabled="!edit.fullEditAvailable || edit.isShared"
               ng-model="edit.item.droughtStress" />
        {{'site.droughtStress.label' | translate}}
    </label>
</div>
<div class="checkbox" style="margin-bottom: 15px">
    <label style="font-weight: 700">
        <input type="checkbox"
               ng-disabled="!edit.fullEditAvailable || edit.isShared"
               ng-model="edit.item.oidiumProne" />
        {{'site.oidiumProne.label' | translate}}
    </label>
</div>
<div class="checkbox" style="margin-bottom: 15px" ng-if="edit.domainWachau">
    <label style="font-weight: 700">
        <input type="checkbox"
               ng-disabled="!edit.fullEditAvailable || edit.isShared"
               ng-model="edit.item.hail" />
        Hagel
    </label>
</div>

<div class="navbar navbar-default sub-menu">
    <div ng-class="{
    'container': !subMenu.fluid,
    'container-fluid': subMenu.fluid
  }">
        <span ng-if="false" ng-repeat-start="item in subMenu.items"></span>
        <button class="btn btn-primary navbar-btn"
                ng-class="{'navbar-right': item.position === 'right'}"
                ng-if="!item.hide && item.type === 'button'"
                ng-click="item.click()"
                ng-disabled="item.isDisabled && item.isDisabled() || !item.isDisabled && ($root.activeRequests > 0 || subMenu.form.$invalid || subMenu.form.$pristine)">
            {{item.text | translate}}
        </button>
        <a class="btn btn-primary navbar-btn"
                ng-href="{{item.url}}"
                ng-class="{'navbar-right': item.position === 'right'}"
                ng-if="!item.hide && item.type === 'link'"
                ng-disabled="item.isDisabled && item.isDisabled() || !item.isDisabled && ($root.activeRequests > 0)">
            {{item.text | translate}}
        </a>

        <!-- Start Download Button -->
        <button class="btn btn-primary navbar-btn"
                ng-class="{'navbar-right': item.position === 'right'}"
                ng-if="!item.hide && item.type === 'download'"
                ng-click="item.click()">
            <i class="glyphicon glyphicon-cloud-download"></i>
        </button>

        <button class="btn btn-primary navbar-btn"
                style="margin-right: 0"
                ng-class="{'navbar-right': item.position === 'right'}"
                ng-if="!item.hide && item.type === 'download-taskdata'"
                ng-click="item.click()"
                uib-tooltip="Taskdata als Zip-Datei herunterladen"
                uib-dropdown-toggle
                uib-tooltip-classes=""
                tooltip-placement="bottom"
                tooltip-append-to-body="true"
                tooltip-class="custom-tooltip">
            <i class="glyphicon glyphicon-cloud-download"></i> TaskData
        </button>

        <button class="btn btn-primary navbar-btn"
                style="margin-right: 5px"
                ng-class="{'navbar-right': item.position === 'right'}"
                ng-if="!item.hide && item.type === 'download-excel'"
                ng-click="item.click()"
                uib-tooltip="Excel-Datei herunterladen"
                uib-tooltip-classes=""
                tooltip-placement="bottom"
                tooltip-append-to-body="true"
                tooltip-class="custom-tooltip">
            <i class="glyphicon glyphicon-cloud-download"></i> Excel
        </button>

        <!-- End Download Button -->

        <ng-form class="navbar-form"
                 ng-class="{
               'navbar-right': item.position === 'right'
             }"
                 ng-if="!item.hide && item.type === 'filter'">
            <div class="btn-group">
                <button class="btn btn-primary"
                        ng-if="!item.hide"
                        ng-click="subMenu.openFilter(item)"
                        ng-disabled="item.isDisabled && item.isDisabled() || $root.activeRequests > 0 || subMenu.form.$invalid || subMenu.form.$pristine">
                    <span ng-if="item.activeFilterCount > 0"
                          class="badge">{{item.activeFilterCount}}</span>{{item.activeFilterCount
                > 0 ? '&nbsp;' : ''}}{{item.text | translate}}
                </button>
                <button class="btn btn-primary"
                        ng-if="item.activeFilterCount > 0"
                        ng-click="item.removeFilter()"
                        ng-disabled="item.isDisabled && item.isDisabled() || $root.activeRequests > 0 || subMenu.form.$invalid || subMenu.form.$pristine">
                    <span class="glyphicon glyphicon-remove"></span>
                </button>
            </div>
        </ng-form>

        <ng-form class="navbar-form"
                 ng-class="{
               'navbar-right': item.position === 'right'
             }"
                 ng-if="!item.hide && item.type === 'uib-btn-radio'">
            <div class="btn-group"
                 ng-disabled="$root.activeRequests > 0 || subMenu.form.$invalid || subMenu.form.$pristine">
                <label class="btn btn-primary"
                       ng-if="item.text.filterFinished"
                       ng-model="item.radioModel"
                       tooltip-placement="bottom-left"
                       uib-tooltip="{{item.tooltip.label.filterFinished | translate }}"
                       tooltip-append-to-body="true"
                       uib-btn-radio="'{{item.text.filterFinished | translate}}'"
                       ng-click="item.click(item.values.filterFinished[0], item.values.filterFinished[1])">
                    {{item.text.filterFinished | translate}}
                </label>
                <label class="btn btn-primary"
                       ng-model="item.radioModel"
                       tooltip-placement="bottom-left"
                       uib-tooltip="{{item.tooltip.label.left | translate }}"
                       tooltip-append-to-body="true"
                       uib-btn-radio="'{{item.text.left | translate}}'"
                       ng-click="item.click(item.values.left[0], item.values.left[1])">
                    {{item.text.left | translate}}
                </label>
                <label class="btn btn-primary"
                       ng-model="item.radioModel"
                       tooltip-placement="bottom-left"
                       uib-tooltip="{{item.tooltip.label.middle | translate }}"
                       tooltip-append-to-body="true"
                       uib-btn-radio="'{{item.text.middle | translate}}'"
                       ng-click="item.click(item.values.middle[0], item.values.middle[1])">
                    {{item.text.middle | translate}}
                </label>
                <label class="btn btn-primary"
                       ng-model="item.radioModel"
                       tooltip-placement="bottom-left"
                       uib-tooltip="{{item.tooltip.label.right | translate }}"
                       tooltip-append-to-body="true"
                       uib-btn-radio="'{{item.text.right | translate}}'"
                       ng-click="item.click(item.values.right[0], item.values.right[1])">
                    {{item.text.right | translate}}
                </label>
            </div>
        </ng-form>
        <ng-form class="navbar-form"
                 ng-class="{
               'navbar-right': item.position === 'right'
             }"
                 ng-if="!item.hide && item.type === 'search'">
            <div class="form-group"
                 ng-class="{
             'has-feedback': item.model
           }">
                <input class="form-control"
                       type="search"
                       placeholder="{{'SEARCH' | translate}}"
                       auto-focus="$root.activeRequests === 0"
                       ng-model="item.model"
                       ng-model-options="{debounce: 500}"
                       ng-change="item.change(item.model)"/>
                <span class="glyphicon glyphicon-remove form-control-feedback text-muted"
                      ng-click="item.change(null)"
                      ng-if="item.model"></span>
            </div>
        </ng-form>
        <ng-form class="navbar-form"
                 ng-class="{
               'navbar-right': item.position === 'right'
             }"
                 ng-if="!item.hide && item.type === 'dropdown'">
            <select class="form-control"
                    name="item.name"
                    ng-change="item.model === null ? item.change() : item.change(item.model)"
                    ng-model="item.model"
                    ng-options="orderType.id as orderType.labelProcessed group by orderType.groupLabel for orderType in item.values | orderBy:['groupPosition', 'groupLabel', 'position', 'labelProcessed']">
                <option value="">{{'ORDER_FILTER_TYPE_ALL' | translate}}</option>
            </select>
        </ng-form>
        <ng-form class="navbar-form"
                 ng-class="{
								 'navbar-right': item.position === 'right'
							 }"
                 ng-if="!item.hide && item.type === 'dropdownExport'">
            <select class="form-control"
                    name="item.name"
                    ng-change="item.model === null ? item.change() : item.change(item.model)"
                    ng-model="item.model"
                    ng-options="jobType.value as jobType.label for jobType in item.values">
                <option value="">{{'export.types.All' | translate}}</option>
            </select>
        </ng-form>
        <ng-form class="navbar-form"
                 ng-class="{
          'navbar-right': item.position === 'right'
        }"
                 ng-if="!item.hide && item.type === 'select'">
            <select class="form-control"
                    ng-disabled="item.isDisabled && item.isDisabled() || !item.isDisabled && ($root.activeRequests > 0)"
                    name="item.name"
                    ng-change="item.onChange(item.model)"
                    ng-model="item.model"
                    ng-options="option.label for option in item.options track by option.id">
                <option value=""
                        ng-if="item.addPlaceholderOption">{{'PLEASE_MAKE_CHOICE' | translate}}
                </option>
            </select>
        </ng-form>
        <span ng-if="false"
              ng-repeat-end></span>
    </div>
</div>

(function () {
  'use strict';

  class ParcelsListCtrl {
    constructor($q, $http, $state, $stateParams, UserService, CrudApi, CustomerMapDataService, permissions, $uibModal, PoiHelper, siteEntities, CostCenterHelper, WineTypeInfo, BatchEditingDescriptorHelper, $document) {
      /* eslint-disable angular/ng_controller_as_vm, angular/ng_controller_as */
      let vm = this,
          promises = {};

      vm.mapId = 'parcel-map-id';
      vm.canDelete = UserService.hasPerm(permissions.WINERY_MANAGEMENT_CAN_DELETE_VINEYARD);
      vm.hasShapeConverterModule = UserService.hasPerm(permissions.SHAPE_CONVERTER_CAN_USE);
      vm.canCreate = UserService.canAccessState('parcels.create');
      // we need to use a separate variable to track whether the batch edit directive can delete
      // as "canDelete" will be potentially set to false once batch editing begins
      vm.batchEditDeleteAvailable = vm.canDelete;
      vm.customerUuid = UserService.user.customerUuid;
      console.log(vm.customerUuid);

      // Initialize some variables
      vm.sorter = $stateParams.sorter || 'label';
      vm.dir = $stateParams.dir || 'asc';
      vm.query = $stateParams.query;
      vm.selection = null;
      vm.polygonsObject = {};
      vm.zoomToSelection = true;
      vm.businessSingular = UserService.getLocalizedLabelOrNull('businessSingular');

      vm.showCheckboxes = false;
      vm.allItemsChecked = false;
      vm.checkedEntities = [];

      vm.filteredVineyards = [];
      vm.itemsPerPage = 100;
      vm.currentPage = 1;
      vm.totalItems = 0;
      vm.domainWachau = UserService.getVcToken().accountUuid === '7f1d4f34-a92d-4b8e-906b-60046d14cbfb';

      vm.vineyardPagination = function() {
        vm.totalItems = vm.items.length;
         let begin = ((vm.currentPage - 1) * vm.itemsPerPage);
         let end = begin + vm.itemsPerPage;
         vm.filteredVineyards = vm.items.slice(begin, end);
      };

      vm.hasAssetType = function(assetType) {
        return (UserService.getAssetType() === assetType);
      };

      vm.pageChanged = function() {
        vm.vineyardPagination();
        $document[0].getElementById('parcelTable').scrollTop = 0;
      };

      vm.getPlotLabels = plots => plots.map(plot => plot.label).sort().join(', ');

      // Add submenu items
      vm.submenu = {
        items: [{
          type: 'button',
          text: 'BUTTON_ADD_MULTIVINEYARD_ORDER',
          click: () => {
            $state.go('orders.create', {type: 'regular'});
          }
        },
        {
          type: 'link',
          text: 'WINERY_MANAGEMENT_BUTTON_ADD_PARCEL',
          url: '/ui/area/create',
          hide: !vm.canCreate,
        },
        {
          type: 'button',
          text: 'PARCELS_PARAM_TAB_8',
          hide: !vm.hasShapeConverterModule,
          click: () => {
            $state.go('parcels.create');
          }
        },
        {
          type: 'filter',
          text: 'FILTER',
          position: 'right',
          controller: 'ParcelListFilterCtrl',
          templateUrl: 'parcels/list/views/filter-modal.tpl.html',
          activeFilterCount: Object.keys(vm.query).length,
          resolve: {
            filters: () => vm.query
          },
          onResult: (filters) => {
            $state.go($state.current.name, {
              page: 1,
              message: null,
              query: filters
            }, {
              reload: true
            });
          },
          removeFilter: () => {
            $state.go($state.current.name, {
              page: 1,
              message: null,
              query: null
            }, {
              reload: true
            });
          }
        },
        {
          type: 'download',
          position: 'right',
          hide: false,
          click: () => {
            let fields = 'id,label,area,business,wineThird,areaCodes,';
            fields += 'isTemporary,plantedDate,clearedDate,district,wineLocation,';
            fields += 'underlay,bbch,stickDistance,laneWidth,managementUnit,costCenter,';
            fields += 'addRowsMethod,numberOfRows,avgStrikeLength,totalStrikeLength,';
            fields += 'numberOfGrapevines,infestedWith,infestedSeverity,';
            fields += 'maximumNitrogenValue,nitrogenDeductionPending,nitrogenDeductionFinished,';
            fields += 'maximumPhosphateValue,phosphateDeductionPending,phosphateDeductionFinished,';
            fields += 'note1,note2,cultivationProgram,yield,flik,';
            fields += 'plotsVineyards,lastModified';
            $http({
              method: 'GET',
              url: '/api/v1/vineyards/export?fieldNames=' + fields,
              responseType: 'blob'
            }).then(function(response) {
              const blob = response.data;
              let urlObject = window.URL.createObjectURL(blob);

              const date = new Date(Date.now());
              let fileName = 'vineyards-' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + '.xlsx';
              let downloadLink = angular.element('<a></a>');
              downloadLink.css('display','none');
              downloadLink.attr('href', urlObject);
              downloadLink.attr('download', fileName);
              downloadLink[0].click();

              // cleanup
              downloadLink.remove();
              window.URL.revokeObjectURL(urlObject);
            });
          }
        }]
      };

      // we don't need to wait for the global pois, we just draw them when they are loaded
      CrudApi.query({
        entity: 'poi',
        onlyGlobal: true
      }, pois => {
        let iconPromises = {};
        pois.forEach(poi => iconPromises[poi.id] = PoiHelper.createIconFromPoi(poi));
        $q.all(iconPromises).then(iconData => vm.iconData = iconData);
      });

      // Get vineyards
      promises.vineyards = CrudApi.query(angular.merge({}, vm.query, {
        entity: siteEntities,
          subentity: 'usable',
        sortBy: vm.sorter,
        sortDir: vm.dir
      }), (data, headers, status) => {
        if (status === 204) {
          vm.message = 'noData';
        }
      }).$promise;

      // Get all businesses to resolve ids
      promises.businesses = CrudApi.query({
        entity: 'businesses',
        subentity: 'usable'
      }).$promise;

      promises.managementUnits = CrudApi.query({
        entity: 'managementUnits'
      }).$promise;

      if (UserService.hasPerm(permissions.TIME_TRACKING_CAN_USE)) {
        promises.costCenters = CrudApi.query({
          entity: 'costCenter'
        }).$promise;
      }

      // Fetch mapdata of all vineyards
      promises.mapData = CustomerMapDataService.getCustomerMapsData();

      vm.rowHover = function (vineyardId, highlight, zoomToSelection = true) {
        if (highlight) {
          vm.selection = vineyardId;
          vm.selectedVineyards = {
            [vineyardId]: true
          };
        } else {
          vm.selection = null;
          vm.selectedVineyards = {};
        }
        vm.zoomToSelection = zoomToSelection;
      };

      // Resolve all promises
      $q.all(promises).then((data) => {
        vm.items = data.vineyards;
        vm.businesses = data.businesses;
        vm.mapData = {};

        WineTypeInfo.whenReady().then(() => {
          let options = {businessId: data.businesses, managementUnitId: data.managementUnits, wineThird: WineTypeInfo.getAllWines()};
          if (UserService.hasPerm(permissions.TIME_TRACKING_CAN_USE)) {
            let costCenters = CostCenterHelper.makeFlat(data.costCenters, 0);
            costCenters.forEach(costCenter => {
              let prefix = '-'.repeat(costCenter.level);
              if (prefix.length) {
                prefix += ' ';
              }
              costCenter.label = prefix.concat(costCenter.label);
            });
            options.costCenterId = costCenters;
          }

          BatchEditingDescriptorHelper.whenReady().then(() => {
            if (vm.hasAssetType('VINEYARD')) {
              vm.fieldDescriptors = BatchEditingDescriptorHelper.getParcelDescriptors(options);
            } else {
              vm.fieldDescriptors = [];
            }
          });
        });
        // only show the map data of vineyards in the list
        vm.items.forEach(vineyard => {
          if (data.mapData.hasOwnProperty(vineyard.id)) {
            vm.mapData[vineyard.id] = data.mapData[vineyard.id];
          }
        });
        vm.vineyardPagination();
      }, () => {
        vm.message = 'loadingError';
      });

      // Sorting method
      vm.sort = (sorter) => {
        $state.go($state.current.name, {
          sorter: sorter,
          dir: vm.dir === 'desc' ? 'asc' : 'desc'
        });
      };

      vm.selectItem = function () {
        let allItemsChecked = true;
        for (let i = 0; i < vm.items.length; i++) {
          if (!vm.items[i].isChecked) {
            allItemsChecked = false;
            break;
          }
        }

        vm.allItemsChecked = allItemsChecked;
        vm.checkedEntities = vm.items.filter(item => item.isChecked === true);
      };

      vm.selectAllItems = function () {
        for (let i = 0; i < vm.items.length; i++) {
          vm.items[i].isChecked = vm.allItemsChecked && !vm.items[i].isShared;
        }

        vm.checkedEntities = vm.items.filter(item => item.isChecked === true);
      };

      vm.delete = (vineyard) => {
        let popup = $uibModal.open({
          controller: 'ParcelsDeleteCtrl',
          controllerAs: 'delete',
          templateUrl: 'parcels/delete/views/delete.tpl.html',
          resolve: {
            item: () => {
              return vineyard;
            },
            business: () => {
              for (let i = 0; i < vm.businesses.length; i++) {
                const business = vm.businesses[i];
                if (business.id === vineyard.businessId) {
                  return business;
                }
              }
              return {};
            }
          }
        });
        popup.result.then(() => {
          CrudApi.delete({
            entity: siteEntities,
            id: vineyard.id,
            lastModified: vineyard.lastModified
          }, () => {
            CustomerMapDataService.removeIdFromCache(vineyard.id);
            $state.go($state.current.name, {
              message: 'deletingSuccess'
            }, {
              reload: true
            });
          }, (response) => {
            if (response.status === 410) {
              vm.message = 'entityHasChanged';
            } else {
              vm.message = 'deletingError';
            }
          });
        });
      };

      vm.onBatchEditCancel = () => {
        $state.go($state.current.name, {
          page: 1,
          message: null,
          query: null
        }, {
          reload: true
        });
      };

      vm.onFilterCheckedSites = () => {
        vm.items = vm.checkedEntities;
        vm.showCheckboxes = false;
        vm.canDelete = false;

        let filterBtn = vm.submenu.items.find(function (element) {
          return element.type === 'filter';
        });
        if (angular.isDefined(filterBtn)) {
          filterBtn.isDisabled = () => true;
        }
      };

      vm.onStepChanged = (step) => {
        if (step === 0) {
          vm.onBatchEditCancel();
        } else if (step === 1) {
          vm.onFilterCheckedSites();
        }
      };
    }
  }

  ParcelsListCtrl.$inject = ['$q', '$http', '$state', '$stateParams', 'UserService', 'CrudApi', 'CustomerMapDataService', 'permissions', '$uibModal', 'PoiHelper', 'siteEntities', 'CostCenterHelper', 'WineTypeInfo', 'BatchEditingDescriptorHelper', '$document'];

  /**
   * @ngdoc object
   * @name parcels.list.controller:ListCtrl
   *
   * @description
   *
   */
  angular
    .module('parcels.list')
    .controller('ParcelsListCtrl', ParcelsListCtrl);
}());
